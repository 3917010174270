import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import Select from 'react-select'
import getCountries from '../../shared/countries'
import { useUser } from 'workflow/hooks/useUser'

const constructCountryObj = country => ({
  value: country.code,
  label: country.name,
})

export const getCountriesOptions = (locale, initialCountries) => {
  const countries = initialCountries ?? getCountries(locale)
  return countries ? countries.map(constructCountryObj) : {}
}

export const getCountryValue = (locale, value, initialCountries) => {
  const countries = initialCountries ?? getCountries(locale)
  const country = countries && countries.find(country => country.code === value)
  return country ? constructCountryObj(country) : {}
}

const CountriesSelect = ({ selected, onChange }) => {
  const { t } = useTranslation()
  const { locale, isLoading } = useUser()

  const countries = useMemo(() => getCountries(locale), [locale])

  return (
    <Select
      placeholder={t('workflow.steps.types.condition.countries.placeholder')}
      value={getCountryValue(locale, selected, countries)}
      options={getCountriesOptions(locale, countries)}
      onChange={onChange}
      isLoading={isLoading}
    />
  )
}

export default CountriesSelect
